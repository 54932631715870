<template>
  <div class="home">
    <div class="top d-f a-c j-b">
      <div class="d-f">
        <div class="avatar">
          <img src="@/assets/image/user_avatar_big.png" alt="" v-if="avatar === null" />
          <img :src="avatar" alt="" v-else />
        </div>
        <div class="detail d-f f-d j-b">
          <div class="username">{{ name }}</div>
          <div class="userid">用户名：{{ username }}</div>
          <div class="userid" v-if="hasOPenId">
            绑定信息：手机号（已绑定） 微信（已绑定）
          </div>
          <div class="userid" v-else>绑定信息：手机号（已绑定） 微信（未绑定）</div>
        </div>
      </div>
      <a-button @click="toInformation">修改个人信息</a-button>
    </div>
    <div class="middle d-f j-b">
      <div class="phone d-f a-c">
        <img src="@/assets/image/user_home_phone.png" class="phone-img" />
        <div class="phone-detail">
          <h3>手机号已绑定</h3>
          <p>
            <span>{{ phone }}</span
            ><a @click="toAccount">修改手机号</a>
          </p>
          <h6>您可以享受到手机相关的登录，安全及提醒服务</h6>
        </div>
      </div>
      <div class="wx d-f a-c">
        <img src="@/assets/image/user_home_wx.png" class="wx-img" />
        <div class="wx-detail" v-if="hasOPenId">
          <h3>微信已绑定</h3>
          <p><a @click="toAccount">解除绑定</a></p>
          <h6>绑定后，您可以享受到微信登录安全及提醒服务</h6>
        </div>
        <div class="wx-detail" v-else>
          <h3>微信未绑定</h3>
          <p><a @click="toAccount">立即绑定</a></p>
          <h6>绑定后，您可以享受到微信登录安全及提醒服务</h6>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="title">我的足迹</div>
      <div class="footer-history d-f j-b">
        <div class="item d-f" @click="toDownload">
          <div>
            <img src="@/assets/image/user_home_icon1.png" class="icon" />
          </div>
          <div class="item-detail">
            <div class="content">我的下载</div>
            <div class="count">{{ downloadCount }}</div>
          </div>
        </div>
        <div class="item d-f" @click="toCollection">
          <div>
            <img src="@/assets/image/user_home_icon2.png" class="icon" />
          </div>
          <div class="item-detail">
            <div class="content">我的收藏</div>
            <div class="count">{{ collectionCount }}</div>
          </div>
        </div>
        <div class="item d-f" @click="toUpload">
          <div>
            <img src="@/assets/image/user_home_icon3.png" class="icon" />
          </div>
          <div class="item-detail">
            <div class="content">我的上传</div>
            <div class="count">{{ uploadCount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasOPenId: false,
      collectionCount: 0,
      downloadCount: 0,
      uploadCount: 0,
    };
  },
  computed: {
    username() {
      let openId = this.$ls.get("userInfo").openId;
      if (openId != null) {
        this.hasOPenId = true;
      } else {
        this.hasOPenId = false;
      }
      return this.$ls.get("userInfo").username;
    },
    name() {
      return this.$ls.get("userInfo").name;
    },
    phone() {
      return this.$ls.get("userInfo").phone;
    },
    avatar() {
      return this.$ls.get("userInfo").avatar;
    },
  },
  methods: {
    toAccount() {
      this.$router.push("/user-account");
    },
    toInformation() {
      this.$router.push("/user-information");
    },
    getRecordAll() {
      this.$api.getRecordAll().then((res) => {
        if (res.code === 200) {
          this.collectionCount = res.data.collectionCount;
          this.downloadCount = res.data.downloadCount;
          this.uploadCount = res.data.uploadCount;
        } else {
          // console.log(res);
          this.$message.error(res.msg);
        }
      });
    },
    toDownload() {
      this.$router.push("/user-download");
    },
    toCollection() {
      this.$router.push("/user-collection");
    },
    toUpload() {
      this.$router.push("/user-upload");
    },
  },
  created() {
    this.getRecordAll();
  },
};
</script>

<style scoped lang="less">
.home {
  width: 936px;
  .top {
    width: 936px;
    height: 198px;
    background: #ffffff;
    opacity: 1;
    border-radius: 4px;
    padding: 0 36px 0 39px;
    box-sizing: border-box;
    .avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .detail {
      margin-left: 27px;
      height: 120px;
      padding: 20px 0;
      box-sizing: border-box;
      .username {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .userid {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #999999;
      }
    }
  }
  .middle {
    width: 936px;
    height: 120px;
    margin-top: 24px;
    .phone {
      width: 456px;
      height: 120px;
      background: #ffffff;
      opacity: 1;
      border-radius: 4px;
      padding: 0 36px;
      box-sizing: border-box;
      .phone-img {
        width: 48px;
      }
      .phone-detail {
        margin-left: 36px;
        h3 {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }
        p {
          margin-top: 12px;
          margin-bottom: 0;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #8e8e8e;
          }
          a {
            margin-left: 24px;
          }
        }
        h6 {
          margin-top: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
    .wx {
      width: 456px;
      height: 120px;
      background: #ffffff;
      opacity: 1;
      border-radius: 4px;
      padding: 0 36px;
      box-sizing: border-box;
      .wx-img {
        width: 48px;
      }
      .wx-detail {
        margin-left: 36px;
        h3 {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
        }
        p {
          margin-top: 12px;
          margin-bottom: 0;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #8e8e8e;
          }
        }
        h6 {
          margin-top: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  .bottom {
    width: 936px;
    height: 179px;
    background: #ffffff;
    opacity: 1;
    border-radius: 4px;
    margin-top: 24px;
    padding: 24px 24px 0 24px;
    box-sizing: border-box;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }
    .footer-history {
      width: 100%;
      margin-top: 30px;
      padding: 0 56px;
      box-sizing: border-box;
      .item {
        cursor: pointer;
        width: 216px;
        height: 68px;
        background: #ffffff;
        opacity: 1;
        border-radius: 4px;
        padding-left: 36px;
        box-sizing: border-box;
        .icon {
          width: 48px;
          margin-top: 9px;
        }
        .item-detail {
          margin-left: 24px;
          margin-top: 6px;
          .content {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
          }
          .count {
            font-size: 14px;
            font-weight: 400;
            color: #d42024;
            margin-top: 6px;
          }
        }
      }
      .item:hover {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
